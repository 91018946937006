<template>
  <div class="flex items-center">
    <FormKit
        type="checkbox"
        :options="options"
        outer-class="!mb-0"
        fieldset-class="!py-0"
        name="saveReport"
        v-model="saveReport"
        :disabled="disabledCheckbox"
    />
  </div>
</template>
<script setup lang="ts">
import {useReportStore} from "~/composables/stores/ReportStore";
import {useAdvanceReportStore} from "~/composables/stores/AdvanceReportStore";
import {useQuery} from "@tanstack/vue-query";

const options = [
  {value: 'saveReport', label: 'Save Report'}
]

const reportStore = useReportStore()
const { saveReport, selectedOptions, reportId } = storeToRefs(reportStore)

const store = useAdvanceReportStore();
const { data, isLoading } = useQuery(['advance-reports'], () => store.getReports(), { ...globalUseQueryOptions() });

const disabledCheckbox = computed(() => {
  const item = data.value?.data.find((item: any) => item.id === reportId.value);
  return item?.selectedColumns.sort().join(',') === selectedOptions.value.sort().join(',');
})
</script>
