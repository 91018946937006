<template>
  <button @click="editForm">
    <VTooltip title="Edit">
      <icon name="material-symbols:edit" class="h-6 w-6" />
    </VTooltip>
  </button>
</template>
<script setup lang="ts">
import {useReportStore} from "~/composables/stores/ReportStore";

const open = ref(false);
const props = defineProps<{
  report: any
}>()

const emit = defineEmits(['close'])
const reportStore = useReportStore()
const { reportId, reportName, saveReport } = storeToRefs(reportStore)
const editForm = () => {
  reportId.value = props.report.id
  reportName.value = props.report.name
  saveReport.value = ['saveReport']
  emit('close')
}
</script>
