<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="emit('close', false)">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl"
              >
                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    @click="emit('close', false)"
                  >
                    <icon name="heroicons:x-mark-solid" class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="px-4 pt-5 pb-4 sm:p-6">
                  <div>
                    <DialogTitle as="h3" class="font-trajan text-2xl text-red-600 mt-2 mb-2"> Manage Saved Report </DialogTitle>
                    <div>
                      <div class="mb-4">
                        <div class="flex justify-between">
                          <div></div>
                          <AdminReportsCreateBtn @close="emit('close')" />
                        </div>
                        <div v-if="!isLoading && !rolesLoading && !advancedLoading">
                          <div class="mt-5">
                            <div
                              v-for="(report, index) in data?.data"
                              :key="report.id"
                              :class="{ 'bg-gray-100': index % 2 === 0 }"
                              class="flex justify-between py-2 pl-1 pr-4"
                            >
                              <div class="w-10/12">
                                <div class="font-semibold max-w-[400px] flex flex-wrap break-all">{{ report.name }}</div>
                              </div>
                              <div class="w-2/12">
                                <div class="flex gap-x-2">
                                  <AdminReportsEditBtn :report="report" @close="emit('close')" />
                                  <AdminReportsDeleteBtn :report="report" />
                                </div>
                              </div>
                            </div>
                            <div v-if="data?.data.length === 0" class="font-semibold">
                              No saved reports.
                            </div>
                          </div>
                        </div>
                        <LoadingOverlay :show="isLoading || rolesLoading || advancedLoading" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="border-t border-gray-500 text-center py-6">
                  <button class="btn-secondary" @click="emit('close', false)">Close</button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useQuery } from '@tanstack/vue-query';
import { useAdvanceReportStore } from '~/composables/stores/AdvanceReportStore';
import { useServiceAreaStore } from '~/composables/stores/ServiceAreaStore';

interface Props {
  open: boolean;
}
defineProps<Props>();

const emit = defineEmits(['close']);

const store = useAdvanceReportStore();
const { data, isLoading } = useQuery(['advance-reports'], () => store.getReports(), { ...globalUseQueryOptions() });

const serviceAreaStore = useServiceAreaStore();
const { data: rolesData, isLoading: rolesLoading } = useQuery(
  ['service-area', 'roles'],
  async () => serviceAreaStore.getServiceAreaRole(false),
  {
    ...globalUseQueryOptions(),
  }
);

const assistanceRequestStore = useAssistanceRequestStore();
const { isLoading: advancedLoading, data: advancedData } = useQuery(
  ['advanced'],
  () => assistanceRequestStore.getAdvanced({}, { perPage: 100000, currentPage: 1 }, { desc: true, id: 'createdAt' }),
  globalUseQueryOptions()
);

const closeModal = () => {
  emit('close');
};
</script>
