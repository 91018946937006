<template>
  <button @click="createNew" class="ml-5 btn-secondary">
    + Create a New Report
  </button>
</template>
<script setup lang="ts">
import {useReportStore} from "~/composables/stores/ReportStore";

const emit = defineEmits(['close'])

const reportStore = useReportStore()
const { reportId, reportName, saveReport, selectedOptions } = storeToRefs(reportStore)

const createNew = () => {
  reportId.value = null
  reportName.value = ''
  saveReport.value = ['saveReport']
  selectedOptions.value = []
  emit('close')
}
</script>
