<template>
  <button
      class="btn-primary py-2"
      type="button"
      @click="openModal"
  >
    Submit
    <AdminReportsReportNameModal :report="report" v-if="open" :open="open" @reportSaved="reportSaved" @close="open = false" />
  </button>
</template>
<script setup lang="ts">
import {useAdvanceReportStore} from "~/composables/stores/AdvanceReportStore";
import {useReportStore} from "~/composables/stores/ReportStore";

const open = ref(false);
interface Props {
  report?: any;
}
const props = defineProps<Props>();
const emit = defineEmits(['reportSaved']);

const store = useAdvanceReportStore()
const { formOptions } = storeToRefs(store)

const reportStore = useReportStore()
const { saveReport, reportName, selectedOptions } = storeToRefs(reportStore)

const router = useRouter()

const adminStore = useAdminStore();
const {reportOpen} = storeToRefs(adminStore);

const openModal = () => {
  if (formOptions.value.length === 0) {
    toastError('', 'Please select at least one option to save the report')
    return;
  }

  if (saveReport.value.length > 0) {
    open.value = true;
    return;
  }

  reportOpen.value = false;
  router.push(`/admin/advanced-filters/report?name=${reportName.value}&columns=${selectedOptions.value.join(',')}`)
}

const reportSaved = () => {
  open.value = false
  router.push(`/admin/advanced-filters/report?name=${reportName.value}&columns=${selectedOptions.value.join(',')}`)
  reportOpen.value = false;
  emit('reportSaved')
}
</script>
