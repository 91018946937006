<template>
  <div v-if="!isLoading && data.data.length" class="border-b border-gray-200 mb-6 pb-6 ">
    <div class="flex justify-between gap-x-2 ">
      <div class="flex items-center gap-x-2">
        <label class="block mb-1 proxima font-bold text-[20px] formkit-invalid:text-red-500" for="input_33"
               id="input_33_label"><span>Saved Reports</span></label>
        <FormKit
            type="dropdown"
            :options="data.data.map((filter: any) => ({value: filter.id, label: filter.name}))"
            name="reportId"
            outer-class="!mb-0"
            wrapper-class="!min-w-[200px]"
            v-model="reportId"
            option-class="max-w-[240px] flex flex-wrap break-all"
            placeholder="Please Select..."
        />
        <button
            class="font-proxima text-white text-sm font-semibold px-6 py-2 rounded-md capitalize bg-red-500 border border-gray-600"
            type="button"
            @click="manageOpen = true"
        >
          Manage
        </button>
      </div>
      <div class="flex items-center">
        <button
            class="font-proxima text-white text-sm font-semibold px-6 py-2 rounded-md capitalize bg-red-500 border border-gray-600"
            type="button"
            @click="clearForm"
        >
          Clear
        </button>
      </div>
    </div>
    <AdminReportsListModal v-if="manageOpen" :open="manageOpen" @close="manageOpen = false"/>
  </div>
</template>
<script setup lang="ts">
import {useQuery} from "@tanstack/vue-query";
import {useAdvanceReportStore} from "~/composables/stores/AdvanceReportStore";
import {useReportStore} from "~/composables/stores/ReportStore";

const store = useAdvanceReportStore();
const {data, isLoading} = useQuery(['advance-reports'], () => store.getReports(), {...globalUseQueryOptions()});

const manageOpen = ref(false);

const reportStore = useReportStore()
const {reportId, selectedOptions, reportName} = storeToRefs(reportStore)


const route = useRoute()
const reportUrlName = route.query.name as string

const findReportItem = () => {
  const item = data.value?.data.find((item: any) => item.name === reportName.value);
  reportId.value = item?.id;
}

findReportItem();
watch(data, () => {
  findReportItem();
});

watch(reportId, (value: string) => {
  if (reportId.value === null) return;
  const item = data.value?.data.find((item: any) => item.id === value);
  reportName.value = item?.name ?? '';
  selectedOptions.value = item?.selectedColumns ?? [];
})

const clearForm = () => {
  reportId.value = null;
  reportName.value = '';
  selectedOptions.value = [];
}
</script>
