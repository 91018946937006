<template>
  <button @click="confirmOpen = true">
    <VTooltip title="Delete">
      <icon name="heroicons:trash-solid" class="h-6 w-6" />
    </VTooltip>
    <ConfirmModal
      :open="confirmOpen"
      title="Are you Sure?"
      context=""
      @close="confirmOpen = false"
      @confirm="deleteReport"
    />
  </button>
</template>
<script setup lang="ts">
import { useAdvanceReportStore } from '~/composables/stores/AdvanceReportStore';
import { useQueryClient } from '@tanstack/vue-query';
import {useReportStore} from "~/composables/stores/ReportStore";

const open = ref(false);
const props = defineProps<{
  report: any;
}>();

const confirmOpen = ref(false);
const store = useAdvanceReportStore();
const { formOptions } = storeToRefs(store);
const { mutate, isLoading } = store.deleteReport(props.report?.id);
const queryClient = useQueryClient();

const reportStore = useReportStore()
const { reportId, reportName, saveReport, selectedOptions } = storeToRefs(reportStore)

const createNew = () => {
  reportId.value = null
  reportName.value = ''
  saveReport.value = []
  selectedOptions.value = []
  useRouter().push('/admin/advanced-filters/report')
}

const deleteReport = () => {
  mutate(
    {},
    {
      onSuccess: () => {
        if (props.report?.id === reportId.value) {
          createNew()
        }
        queryClient.invalidateQueries(['advance-reports']);
        toastSuccess('', 'Report deleted successfully');
        confirmOpen.value = false;
      },
    }
  );
};
</script>
