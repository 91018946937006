<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="emit('close', false)">
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                  class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl"
              >
                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                      type="button"
                      class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      @click="emit('close', false)"
                  >
                    <icon name="heroicons:x-mark-solid" class="h-6 w-6" aria-hidden="true"/>
                  </button>
                </div>
                <div class="px-4 pt-5 pb-4 sm:p-6">
                  <div>
                    <DialogTitle as="h3" class="font-trajan text-2xl text-red-600 mt-2 mb-2"> Reports</DialogTitle>
                    <div>
                      <div class="mb-4">
                        <div>
                          <div class="mt-5">
                            <div>
                              <AdminReportsReportDropdown />
                            </div>
                            <div class="mb-8">
                              <FormKit
                                  type="checkbox"
                                  :options="[{ value: 'all-options', label: 'Select All' }]"
                                  v-model="selectAll"
                                  label-class="!text-black"
                              />
                            </div>
                            <div v-for="(opt, key) in options">
                              <h1 class="font-bold text-black pl-4">{{ key }}</h1>
                              <FormKit
                                  type="checkbox"
                                  :options="[{ value: key, label: 'Select all in this group' }]"
                                  v-model="groupSelectedOptions"
                                  outer-class="!mb-0"
                                  label-class="!text-black"
                              />
                              <FormKit
                                  type="checkbox"
                                  :options="opt"
                                  options-class="$reset grid grid-cols-4 gap-x-4"
                                  fieldset-class="!max-w-full"
                                  v-model="selectedOptions"
                              >
                                <template #label="context">
                                  <span class="block mb-1 proxima text-[16px] text-gray-500 ml-2"
                                        v-html="context.option.label"></span>
                                </template>
                              </FormKit>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" flex justify-center border-t border-gray-500 text-center py-6">
                  <AdminReportsSaveBtn :report="report" @reportSaved="reportSaved"/>
                  <AdminReportsSaveCheckbox />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup lang="ts">
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {useAdvanceReportStore} from '~/composables/stores/AdvanceReportStore';
import {reportFieldValues} from '~/utils/helpers';
import {useReportStore} from "~/composables/stores/ReportStore";
import {useQuery} from "@tanstack/vue-query";

interface Props {
  open: boolean;
  report?: any;
}

const props = defineProps<Props>();

const emit = defineEmits(['close']);
const reportSaved = () => {
  emit('close', false);
};
const d = await reportFieldValues();
const options = ref(d.reduce((acc, item) => {
  // Create key if it doesn't exist
  if (!acc[item.groupName]) {
    acc[item.groupName] = [];
  }
  // Push item to the array
  acc[item.groupName].push(item);
  return acc;
}, {}));

const store = useAdvanceReportStore();
const {formOptions} = storeToRefs(store);

const reportStore = useReportStore()
const { selectedOptions, reportId,  reportName, saveReport } = storeToRefs(reportStore)

watch(selectedOptions, (value) => {
  formOptions.value = value;
});

const selectAll = ref();
watch(selectAll, (value, oldValue) => {
  if (oldValue !== undefined) {
    if (value.length) {
      Object.keys(options.value).forEach((key) => {
        options.value[key].forEach((item) => {
          if (!selectedOptions.value.includes(item.value)) {
            selectedOptions.value.push(item.value);
          }
        });
      });
      groupSelectedOptions.value = Object.keys(options.value);
    } else {
      selectedOptions.value = [];
      groupSelectedOptions.value = [];
    }
  }
});

const groupSelectedOptions = ref();
watch(groupSelectedOptions, (value, oldValue) => {
  if (oldValue !== undefined) {
    // get difference between old and new value
    const diff = value.filter((x) => !oldValue.includes(x));
    // inverse diff
    const diff2 = oldValue.filter((x) => !value.includes(x));


    if (value.length) {
      if (diff.length) {
        options.value[diff[0]].forEach((item) => {
          if (!selectedOptions.value.includes(item.value)) {
            selectedOptions.value.push(item.value);
          }
        });
      }
      if (diff2.length) {
        options.value[diff2[0]].forEach((item) => {
          if (selectedOptions.value.includes(item.value)) {
            selectedOptions.value = selectedOptions.value.filter((x) => x !== item.value);
          }
        });
      }
    } else {
      selectedOptions.value = [];
    }
  }
});

const { data, isLoading } = useQuery(['advance-reports'], () => store.getReports(), { ...globalUseQueryOptions() });
/*watch(selectedOptions, (value) => {
  data.value?.data?.map((report: any) => {
    if (report.selectedColumns.sort().join(',') === value.sort().join(',')) {
      reportId.value = report.id;
      reportName.value = report.name;
    }
  })
})*/
onUnmounted(() => {
  saveReport.value = []
})
</script>
