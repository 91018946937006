<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="emit('close', false)">
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                  class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl"
              >
                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                      type="button"
                      class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      @click="emit('close', false)"
                  >
                    <icon name="heroicons:x-mark-solid" class="h-6 w-6" aria-hidden="true"/>
                  </button>
                </div>
                <FormKit type="form" @submit="submit">
                  <div class="px-4 pt-5 pb-4 sm:p-6">
                    <div>
                      <DialogTitle as="h3" class="font-trajan text-2xl text-red-600 mt-2 mb-2"> Report Name
                      </DialogTitle>
                      <div>
                        <div class="mb-4">
                          <div>
                            <div class="mt-5">
                              <FormKit
                                  type="text"
                                  validation="required|length:0,50"
                                  name="name"
                                  :value="reportName"
                                  validation-visibility="live"
                                  :validation-messages="{
                                  length: 'Limited to 50 characters ',
                                }"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="border-t border-gray-500 text-center pt-6">
                    <button
                        class="font-proxima text-black text-sm font-semibold px-6 py-2 rounded-md capitalize border border-gray-600 mr-4"
                        @click="emit('close', false)"
                        type="button"
                    >
                      Cancel
                    </button>
                    <button
                        class="font-proxima text-sm font-semibold text-white border border-blue-primary bg-blue-primary active:bg-blue-800 hover:bg-blue-800 rounded-md px-6 py-2 mr-4"
                        type="submit"
                        :disabled="isLoading"
                    >
                      {{ isLoading ? 'Saving...' : 'Save' }}
                    </button>
                  </div>
                  <div class="pl-6 pb-6">
                    <FormKitMessages/>
                  </div>
                  <ConfirmModal
                      :title='`<span class="sm:max-w-lg flex flex-wrap">"${formData.name}"</span> already exists.  Do you want to replace it?`'
                      :context="`A saved report with the same name already exists. Replacing it will overwrite its current definition.`"
                      :open="confirmOpen"
                      v-if="confirmOpen"
                      @close="confirmOpen = false"
                      @confirm="saveFormReport(formData, eraseId)"/>
                </FormKit>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup lang="ts">
import {FormKitMessages} from '@formkit/vue';
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {useQuery, useQueryClient} from '@tanstack/vue-query';
import {useAdvanceReportStore} from '~/composables/stores/AdvanceReportStore';
import {useReportStore} from "~/composables/stores/ReportStore";

interface Props {
  open: boolean;
  report?: any;
}

const props = defineProps<Props>();

const emit = defineEmits(['close', 'reportSaved']);
const store = useAdvanceReportStore();
const {formOptions} = storeToRefs(store);
const {mutate, isLoading} = store.saveReport();
const queryClient = useQueryClient();

const reportStore = useReportStore()
const {reportName, saveReport, selectedOptions} = storeToRefs(reportStore)

const advancedStore = useAdvanceReportStore();
const {data} = useQuery(['advance-reports-names'], () => advancedStore.getReports(), {...globalUseQueryOptions()});

const formData = ref()
const eraseId = ref()
const submit = (form: any) => {
  formData.value = form;
  const item = data.value?.data?.find((report: any) => report.name.trim().toLowerCase() === form.name.trim().toLowerCase())

  if (item) {
    eraseId.value = item.id;
    confirmOpen.value = true;
    return;
  }

  saveFormReport(form);
}
const saveFormReport = (form: any, id?: string | undefined) => {
  mutate(
      {
        ...form,
        selectedColumns: selectedOptions.value,
        reportId: id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['advance-reports']);
          toastSuccess('', 'Report saved successfully');
          reportName.value = form.name;
          saveReport.value = []
          emit('reportSaved', false);
        },
      }
  );
};

const confirmOpen = ref(false);
</script>
