<template>
  <nuxt-link @click="openReport" class="py-5 px-3 font-proxima text-black text-sm font-semibold hover:text-red-500 cursor-pointer">
    Custom Report
  </nuxt-link>
</template>
<script setup lang="ts">
const adminStore = useAdminStore();
const {reportOpen} = storeToRefs(adminStore);

const reportStore = useReportStore();
const {reportName, reportId, selectedOptions} = storeToRefs(reportStore);

const openReport = () => {
  reportName.value = '';
  reportId.value = null;
  selectedOptions.value = [];
  reportOpen.value = true
  useRouter().push('/admin/advanced-filters/report')
}
</script>
